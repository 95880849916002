import { gql } from '@apollo/client';

export const GET_ELEMENTS = gql`
  query GetElements($projectId: Int!) {
    elements(parent_id: $projectId) {
      list {
        id
        name
        children_count
      }
    }
  }
`;
