import React from 'react';
import Menu from "../components/Menu";
import Welds from "../components/Welds";
const WeldsPage = () => {
 return (
  <>
   <Menu />
   <Welds />
  </>
 );
}

export default WeldsPage;
