import { useQuery } from '@apollo/client';
import { Box, Button, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { default as React, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_WELDS } from '../graphql/getWelds';


export default function Welds() {
  const navigate = useNavigate();

  const [projectId] = useState(Number(useParams().projectId))

  const { data, loading, error } = useQuery(GET_WELDS, {
    variables: { pageSize: 200, page: 1, projectId: projectId },
  });

  const [filters, setFilters] = useState({
    no: '',
    weld_type: '',
    length: '',
    material_1: '',
    material_2: '',
    wps: '',
    status: '',
  });

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  if (loading) return <Box sx={{ width: '100%' }}><LinearProgress /></Box>
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const welds = data?.welds?.list || [];
  
  const filteredWelds = welds.filter((weld) =>
    Object.keys(filters).every((key) => {
      const filterValue = filters[key];
      if (!filterValue) return true;

      const columnValue =
        key === 'weld_type'
          ? weld.weld_type.name
          : key === 'material_1'
          ? weld.material_1.name
          : key === 'material_2'
          ? weld.material_2.name
          : key === 'wps'
          ? weld.wps?.no || ''
          : String(weld[key]);

      const normalizedFilter = filterValue.toLowerCase().replace(/_/g, ' ');
      const normalizedColumn = columnValue.toLowerCase().replace(/_/g, ' ');

    return normalizedColumn.includes(normalizedFilter);
    })
  );

  const statusButton = (weld) => {
    switch (weld.status) {
      case "new":
        return <Button variant="contained" color="primary" sx={{fontSize:"10px", width:"22px"}}> {weld.status}</Button>;
      case "in_progress":
        return <Button variant="contained" sx={{ backgroundColor: "#D5D5D5", fontSize:"10px" }}>{weld.status}</Button>;
      case "ready":
        return <Button variant="contained" sx={{ backgroundColor: "#398E3C", fontSize:"10px", width:"22px" }}> {weld.status}</Button>
      case "faild":
        return <Button variant="contained" sx={{ backgroundColor: "#FC0101", fontSize:"10px", width:"22px" }}> {weld.status}</Button>
      case "ok":
        return <Button variant="contained" sx={{ backgroundColor: "#359843", fontSize:"10px", width:"22px" }}> {weld.status}</Button>
      case "inconsistent":
        return <Button variant="contained" sx={{ backgroundColor: "#532D8E", fontSize:"10px", width:"22px" }}> {weld.status}</Button>                          
      default:
        return <Button variant="contained" sx={{ backgroundColor: "#D5D5D5", fontSize:"10px" }}>{weld.status}</Button>;
    }
  };
  
  const handleOnClick = () => {
    navigate(`/weld/${projectId}`);
  }

  const actionButton = (weld) => {
    return weld.status === "ready"
      ? null 
      : (
        <Button 
        onClick={() => { handleOnClick(); }} 
        variant="outlined" 
        size="small" 
        sx={{ marginRight: "0px", float: "right" }}>
          Weld
        </Button>
      );
  };
  

  return (
    <Container sx={{ paddingTop: 4 }}>
    {welds.length > 0 
    ? (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="welds table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                label="No"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('no', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Weld Type"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('weld_type', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Length"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('length', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Material 1"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('material_1', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Material 2"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('material_2', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="WPS"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('wps', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Status"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleFilterChange('status', e.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell>Weld Type</TableCell>
            <TableCell>Length</TableCell>
            <TableCell>Material 1</TableCell>
            <TableCell>Material 2</TableCell>
            <TableCell>WPS</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredWelds.map((weld) => (
            <TableRow key={weld.id}>
              <TableCell>{weld.no}</TableCell>
              <TableCell>{weld.weld_type.name}</TableCell>
              <TableCell>{weld.length}</TableCell>
              <TableCell>{weld.material_1.name}</TableCell>
              <TableCell>{weld.material_2.name}</TableCell>
              <TableCell>{weld.wps?.no || '-'}</TableCell>
              <TableCell>{statusButton(weld)}</TableCell>
              <TableCell>{actionButton(weld)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
    : "No welds to display" }
    </Container>

  );
}
