import React from 'react';
import Menu from "../components/Menu";
import WeldsLogForm from "../components/WeldsLogForm";
const WeldsLogFormPage = () => {
  return (
    <>
      <Menu />
      <WeldsLogForm />
    </>
  );
}

export default WeldsLogFormPage;
