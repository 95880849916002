import { gql } from '@apollo/client';

export const GET_WELDERS = gql`
  query GetWelders {
    people(
      filters: {
        permission_key: "core.people.welder"
        active: "true"
      }
    ) {
      list {
        id
        name
      }
    }
  }
`;
