import { gql } from '@apollo/client';

export const GET_WELDS = gql`
  query GetWelds($projectId: ID!) {
    welds(project_id: $projectId, page: 1, size: 1000, sort_column: "id", sort_direction: "ASC") {
      list {
        id
        no
        weld_type {
          name
        }
        length
        material_1 {
          name
        }
        material_2 {
          name
        }
        wps {
          no
        }
        extra_data
        status
      }
    }
  }
`;
