import { useQuery } from '@apollo/client';
import { Alert, Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import { GET_PROJECTS } from '../graphql/getProjects';

export default function ProjectList() {
  const { loading, error, data } = useQuery(GET_PROJECTS);

  if (loading) return <Box sx={{ width: '100%' }}><LinearProgress /></Box>
  if (error) return <Alert severity="error">Error: {error.message}</Alert>;

  return (
    <Container sx={{ paddingTop: 4 }}>
      <Typography variant="h4" gutterBottom>Project List</Typography>
      <List>
        {data.projects.list.map((project) => (
          <ListItem 
            key={project.id} 
            divider 
            button 
            component={Link} 
            to={`/project/${project.id}`}
            sx={{
              '&:hover': {
                backgroundColor: 'primary.light',
                color: 'white',
              },
              cursor: 'pointer',
            }}
          >
            <ListItemText
              primary={project.name}
              secondary={`Elements count: ${project.children_count}`}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
