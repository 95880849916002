import React from 'react';
import Elements from "../components/Elements";

import Menu from "../components/Menu";

const ProjectElementsPage = () => {

  return (
    <>
      <Menu />
      <Elements />
    </>
  );
}

export default ProjectElementsPage;
