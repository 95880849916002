import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Container, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useState } from 'react';
import { GET_WELDERS } from '../graphql/getWelders';
const WeldsLogForm = () => {
  const { data, loading, error } = useQuery(GET_WELDERS);

  const [welderList] = useState(data?.people ? data.people.list.map((welder) => welder.name) : []);
  const [inputs, setInputs] = useState([{ welder: '', percentage: '', date: "", note: ""}]);
  const [isAdd, setIsAdd] = useState(true); 

  const handleAddInput = () => {
    setInputs([...inputs, { welder: '', percentage: '' }]);
  };
  
  const handleRemoveInput = () =>{
    console.log("Remove") // dodać obsługę jak klikne to usuwa się wiersz cały 
  }

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index][event.target.name] = event.target.value;
    setInputs(values);
  };
  const handleChangeDateOrNote = (e) => {
    const { name, value } = e.target;
  
    setInputs((prevInputs) =>
      prevInputs.map((input, index) =>
        index === 0 
          ? { ...input, [name]: value } 
          : input
      )
    );
  };
  if (loading) return <Box sx={{ width: '100%' }}><LinearProgress /></Box>
  if (error) return <Alert severity="error">Error: {error.message}</Alert>;

  console.log(inputs, "<---")
  console.log("---")

  console.log(inputs, "<---2")

  return (
    <Container sx={{ paddingTop: 4 }}>      
      <Typography variant="h6" gutterBottom>
        Welds Log
      </Typography>
      {inputs.map((input, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Choose Welder"
              name="welder"
              value={input.welder}
              fullWidth
              required
              onChange={(e) => handleInputChange(index, e)}
              sx={{marginTop: 2}}

            >
              {welderList.map((welder, i) => (
                <MenuItem key={i} value={welder}>
                  {welder}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Percentage"
              name="percentage"
              value={input.percentage}
              fullWidth
              required
              sx={{marginTop: 2}}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <IconButton onClick={handleAddInput} color="primary" sx={{marginTop: 2}}>
              <AddIcon /> 
            </IconButton>
            <IconButton onClick={handleRemoveInput}color="primary" sx={{marginTop: 2}}>
              <RemoveIcon /> 
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box sx={{ display: 'flex', flexDirection:"column", justifyContent: 'center', mt: 4 }}>
      <Grid item xs={12} sm={3}>
            <TextField
              type="date"
              label="Date"
              name="date"
              value={inputs[0].date}
              fullWidth
              required
              sx={{marginTop: 2}}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeDateOrNote}

            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Notes"
              name="note"
              value={inputs[0].note}
              fullWidth
              multiline
              rows={4}
              sx={{marginTop: 4}}
              onChange={handleChangeDateOrNote}
            />
          </Grid>
        <Button sx={{marginTop: 4}}variant="contained" color="primary">
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default WeldsLogForm;
